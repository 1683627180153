import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'esic',
		component: () => import('@/views/private/esic/Esic.vue')
	},
	{
		path: '/atendimento',
		name: 'atendimento',
		component: () => import('@/views/public/atendimento/Atendimento.vue')
	},
	{
		path: '/recognition',
		name: 'login',
		component: () => import('@/views/public/login/Login.vue')
	}, 	               
	{
		path: '/process/:id',
		name: 'process',
		component: () => import('@/views/private/processPage/ProcessPage.vue')
	},
	{
		path: '/question',
		name: 'question',
		component: () => import('@/views/private/question/Question.vue')
	},
	{
		path: '/estatisticas',
		name: 'estatisticacas',
		component: () => import('@/views/private/estatisticacas/Estatisticacas.vue')
	},
	{
		path: '/relatorios',
		name: 'relatorios',
		component: () => import('@/views/private/relatorios/Relatorios.vue')
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('@/views/private/registerPage/RegisterPage.vue')
	},
	{
		path: '/search',
		name: 'search',
		component: () => import('@/views/private/searchProcess/SearchProcess.vue')
	},
	{
		path: '*',
		name: '404',
		component: () => import('@/views/public/page404/Page404.vue')
	},
]

export default routes