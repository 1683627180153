import { Component, Vue } from 'vue-property-decorator'

//esic components
import TopLine from '@/components/esic/eTopLine/eTopLine.vue'
import Footer from '@/components/portalFooter/PortalFooter.vue'

@Component({
	components: {
		TopLine,
		Footer
	}
})
export default class App extends Vue {}