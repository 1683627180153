import { ComponentVersion } from '@/components/version'

export const SYSTEM_PROD_MODE = true

export const VERSION = 'Versão 1.0.0'+ComponentVersion

export const PortalName = "Prefeitura Municipal de Laranja da Terra"
export const PortalSite = "www.laranjadaterra.es.gov.br"

export const API_BASE = {
	DEV:'https://apilaranjadaterra.espiritocidadao.org',
	PROD:'https://apilaranjadaterra.espiritocidadao.org',
}

export function UrlBase(): string {
	if(SYSTEM_PROD_MODE){		
		return API_BASE.PROD
	} console.warn("[main] System in Devmode")
	return API_BASE.DEV
}

export function Version(): string{
	if(SYSTEM_PROD_MODE){
		return VERSION
	} return `Devmode | ${VERSION}`
}

export function setConfigSidebar(value: any): void {
	return localStorage.setItem("configSidenav", `${value}`);
}

export function getConfigSidebar(): string | null{
	return localStorage.getItem("configSidenav");
}