import { Component, Prop, Vue } from 'vue-property-decorator'
import { isEmpty } from '@/utils'

//wrappers
import {
	wIcon,
} from '@/components/wrappers';

@Component({
	components:{
		wIcon,
	}
})
export default class SmoothDropdown extends Vue {
	@Prop() icon!: string
	@Prop({default: ''}) link!: string
	@Prop({default: ''}) router!: string
	@Prop({default: true}) isDopdown!: boolean

	switch = false
	bgColor = ""

	get backgroundColor(): string {
		return this.bgColor
	}

	get display(): boolean {
		return this.switch
	}

	switchDisplay(): void {
		if(this.isDopdown && isEmpty(this.link) && isEmpty(this.router)){
			this.switch = !this.switch 
		}else if(!isEmpty(this.link)){
			window.open(this.link, '_blank');
		}else if(!isEmpty(this.router)){
			this.$router.push(this.router)
		}
		if(this.switch){
			this.bgColor = "rgb(240, 239, 239)"
		}else{
			this.bgColor = ""
		}
	}
}
