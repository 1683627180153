import { AuthState } from './auth';

export default {
	loader(state: AuthState): boolean {
		return state.loader;
	},
	name(state: AuthState): string {
		return state.name;
	},
	error(state: AuthState): any {
		return state.error;
	},
};