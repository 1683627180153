import { Component, Vue } from 'vue-property-decorator'

//global variables
import { PortalName } from '@/config'

//wrappers
import {
	WColumn,
	WColumns,
	SizedBox,
	WLine,
	wIcon
} from '@/components/wrappers';

@Component({
	components:{
		WColumn,
		WColumns,
		SizedBox,
		WLine,
		wIcon
	}
})
export default class TopLine extends Vue { 
	portalName = PortalName.toLocaleUpperCase()

	get userName(): string {
    return this.$store.getters['auth/name']
  }

	goToHome(): void {
		this.$router.push('/')
	}

	get isBack(): boolean {
		return this.$route.path == '/' || this.$route.path == '/esic'
	}
}