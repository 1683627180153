import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { SidebarItem, SidebarOptions } from '../molds/SidebarItem';

@Component
export default class Sidebar extends Vue { 
	@Prop() sidebarOptions!: SidebarOptions[]
	@Prop() open!: boolean
	@Prop() selectItem!: number
		
	mounted(){
		this.closeNav()
		this.activeDropDown(6)
	}

	@Watch('open') openSidebar(newOpen: boolean){
		if(newOpen){
			this.openNav()
		}else{
			this.closeNav()
		}		
	}

	setSelect(index: number){
		this.$emit("itemSelect", index)
	}

	select(index: number): string {
		if(this.selectItem == index){
			this.selectItem = index
			return "item-select"
		} return ""
	}

	// selectDropdown(index: number): string {
	// 	if(this.selectItem == index){
	// 		this.selectItem = index
	// 		return "item-select"
	// 	} return ""
	// }
	
	get sidenav(): HTMLElement | null{
		return document.getElementById("sidenav")
	}

	get navItem(): HTMLElement | null{
		return document.getElementById("nav-item")
	}

	itemClick(item: SidebarItem, index: number, isSelect: boolean): void {
		if(isSelect) {
			this.setSelect(index)
		}
		this.$emit('itemClick', item)
	}

	getElement(idElement: string): HTMLElement | null{
		return document.getElementById(idElement)
	}

	rotateArrow(idRow: number, rotate: boolean): void {
		const arrow = this.getElement(`arrow-${idRow}`)
		if(arrow){		
			if (rotate) {
				arrow.style.rotate = "180deg"
			} else {
				arrow.style.rotate = "0deg"
			}
		}
	}

	activeDropDown(id: number): void {		
		const menuElement = this.getElement(`dropdown-container-${id}`)
		if(menuElement){
			if(menuElement.style.display == "inherit"){
				this.rotateArrow(id, false)
				menuElement.style.display = "none"
			}else{
				this.rotateArrow(id, true)
				menuElement.style.display = "inherit"
			}
		}
	}

	openNav(): void {
		if(this.sidenav){
			// this.sidenav.style.width = "250px";
			this.sidenav.style.display = "block";
		}
	}

	closeNav(): void {		
		if(this.sidenav){
			// this.navItem.style.visibility = "none"
			// this.sidenav.style.width = "0";
			this.sidenav.style.display = "none";
		}
	}

}
