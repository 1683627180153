import { Process, ProcessState } from './process'
import { WolfApiData } from "@/components/wrappers/molds/TableOptions";

export default {
	setProcess(state: ProcessState, process: Process[]): void {
		state.process = process
	},
	setSelectProcess(state: ProcessState, process: Process): void {
		state.selectProcess = process
	},
	setSearchProcess(state: ProcessState, wolfApiProcess: WolfApiData): void {
		state.wolfApiProcess = wolfApiProcess
	},
  setGroupProces(state: ProcessState, wolfApiProcess: WolfApiData): void {
		state.wolfApiProcess = wolfApiProcess
	},
	setLoader(state: ProcessState, loader: boolean): void {
		state.loader = loader
	},
	setLoaderList(state: ProcessState, loader: boolean): void {
		state.loaderList = loader
	},
	setError(state: ProcessState, error: any): void {
		state.error = error
	},
}
	
