import { AuthState } from './auth';

export default {
	setLoader(state: AuthState, loader: boolean): void {
		state.loader = loader
	},
	setName(state: AuthState, name: string): void {
		state.name = name
	},
	setError(state: AuthState, error: any): void {
		state.error = error
	}
}