import Vue from 'vue'
import VueRouter from 'vue-router'
import { verifyToken } from '@/services/api'
import routes from './routes';

Vue.use(VueRouter)

const app = document.getElementById('app')

const nameRoutesNoProtect = [
	'login',
	'esic'
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
	scrollBehavior() {
		if(app != null){
			app.scrollIntoView()
		}
		
	}
})
// router protect
router.beforeEach((to, from, next) => {
	if(verifyToken()){
		next()
	}else{
			if(to.name == "register"){
				next('/recognition?route=register')
			}
			else if(to.name == "search"){
				next('/recognition?route=search')
			}else{
				next()
			}
		}
	})
export default router
